<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Tambah Cabang
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container />
    <div id="container-modal-add-user" />
    <modal-out-confirm :target-path="targetPath" />
    <modal-save-confirm />
  </div>
</template>

<script>
import Container from './Container'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    BreadCrumb
  },
  data() {
    return {
      name: 'Cabang',
      status: 'Tambah Vendor',
      targetPath: '',
      breadcrumb: [
        {
          link: '/admin/cabang',
          title: 'Cabang'
        },
        {
          link: null,
          title: 'Tambah Vendor'
        }
      ]
    }
  },
  beforeRouteLeave (to) {
    this.targetPath = to.fullPath
    return window.UIkit.modal('#modal-out-confirm').show()
  }
}
</script>
